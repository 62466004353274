<template>
  <div class="vl-parent">
    <loading :active="isLoading" :can-cancel="true" :is-full-page="true" />
    <Layout>

      <PageHeader :title="title" :items="items" />

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div>
                <a href="javascript:void(0);" v-if="this.$route.params.id == undefined" class="mr-3 text-secondary" v-b-tooltip.hover data-toggle="tooltip"
                  title="Home">
                  <span class="mt-0" >Home</span>

                </a>
                <a href="javascript:void(0);" v-else class="mr-3 text-secondary" v-b-tooltip.hover data-toggle="tooltip"
                  title="Kembali" @click="$router.go(-1)">
                  <span class="mt-0"><i class="mdi mdi-chevron-left  font-size-16"> </i>{{ folderName }}</span>

                </a>
                <b-dropdown right toggle-class="arrow-none card-drop" variant="white">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </template>
                  <!-- item-->
                  <b-dropdown-item v-b-modal.modal-file> <i class="mdi mdi-file-plus-outline"></i> Tambah
                    File</b-dropdown-item>
                  <!-- item-->
                  <b-dropdown-item v-b-modal.modal-center><i class="mdi mdi-folder-plus-outline"></i> Buat
                    Folder</b-dropdown-item>
                  <!-- item-->
                  <b-dropdown-item><i class="mdi mdi-cloud-download-outline"></i> Download</b-dropdown-item>

                </b-dropdown>



              </div>



            </div>
            <div class="col-md-6">
              <div style="float: right;"><i class="ri ri-layout-grid-fill"></i></div>
            </div>
          </div>

        </div>
      </div>
      <div class="text-center" v-if="listData.length == 0">
        <h5>Tidak ada file dan folder</h5>
      </div>
      <div class="card" v-else>
        <ul class="list-unstyled chat-list">
          <li v-for="(data, index) in listData" :key="index" @contextmenu.prevent.stop="handleClick($event, data)">
            <a v-if="data.tipe_item == 'folder'" :href="'/project/repository/' + data.id">
              <div class="media">
                <div class="user-img align-self-center mr-3">
                  <img v-if="data.tipe_item == 'folder'"
                    :src="`https://img.icons8.com/color/96/null/folder-invoices--v1.png`"
                    class="rounded-circle avatar-xs" />
                  <img v-else :src="`https://img.icons8.com/3d-fluency/94/null/pdf.png`"
                    class="rounded-circle avatar-xs" />
                </div>
                <div class="media-body overflow-hidden">
                  <h5 class="text-truncate font-size-14 mb-1">
                    {{ data.nama_item }}
                  </h5>
                  <p class="text-truncate mb-0">
                    {{ formattedDate(data.updatedAt) }}
                  </p>
                </div>
              </div>
            </a>
            <a v-else :href="data.filename" target="_blank">
              <div class="media">
                <div class="user-img align-self-center mr-3">
                  <img v-if="cekEkstensi(data.filename) == 'pdf'"
                    :src="`https://img.icons8.com/3d-fluency/94/null/pdf.png`" class="rounded-circle avatar-xs" />
                  <img v-else-if="cekEkstensi(data.filename) == 'xls' || cekEkstensi(data.filename) == 'xlsx'"
                    :src="`https://img.icons8.com/fluency/48/null/microsoft-excel-2019.png`"
                    class="rounded-circle avatar-xs" />
                  <img v-else-if="cekEkstensi(data.filename) == 'doc' || cekEkstensi(data.filename) == 'docx'"
                    :src="`https://img.icons8.com/fluency/48/null/microsoft-word-2019.png`"
                    class="rounded-circle avatar-xs" />
                  <img v-else-if="cekEkstensi(data.filename) == 'zip'"
                    :src="`https://img.icons8.com/color/48/null/zip.png`" class="rounded-circle avatar-xs" />
                  <img v-else-if="cekEkstensi(data.filename) == 'rar'"
                    :src="`https://img.icons8.com/color/48/null/rar.png`" class="rounded-circle avatar-xs" />
                  <img
                    v-else-if="cekEkstensi(data.filename) == 'png' || cekEkstensi(data.filename) == 'jpeg' || cekEkstensi(data.filename) == 'jpg'"
                    :src="data.filename" class="rounded-circle avatar-xs" />
                  <img
                    v-else-if="cekEkstensi(data.filename) == 'ppt' || cekEkstensi(data.filename) == 'pptx' || cekEkstensi(data.filename) == 'jpg'"
                    :src="'https://img.icons8.com/color/48/null/ms-powerpoint--v1.png'"
                    class="rounded-circle avatar-xs" />
                  <img v-else :src="`https://img.icons8.com/windows/32/null/file.png`" class="rounded-circle avatar-xs" />
                </div>
                <div class="media-body overflow-hidden">
                  <h5 class="text-truncate font-size-14 mb-1">
                    {{ data.nama_item }}
                  </h5>
                  <p class="text-truncate mb-0">
                    {{ formattedDate(data.updatedAt) }}
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- modal create folder -->
      <b-modal id="modal-center" centered title="Buat Folder" title-class="font-18" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label-for="name-input" invalid-feedback="Name is required" label="Nama Folder">
            <b-form-input id="name-input" v-model="formFolder.folderName" required
              placeholder="Nama Folder"></b-form-input>
          </b-form-group>
          <b-form-group label="Dapat diakses">
            <b-form-select v-model="formFolder.is_public" :options="options_public"></b-form-select>
          </b-form-group>
          <b-form-group label="Posisi yang dapat melihat" v-if="formFolder.is_public == false">
            <multiselect v-model="formFolder.posisiLihat" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>
          </b-form-group>
          <b-form-group label="Posisi yang dapat mengubah">
            <multiselect v-model="formFolder.posisiEdit" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>

          </b-form-group>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"
            @click="tambahFolder(); $bvModal.hide('modal-center')">Buat</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
        </div>
      </b-modal>

      <!-- modal folder edit -->
      <b-modal id="modal-folder-edit" centered title="Edit Folder" title-class="font-18" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label-for="name-input" invalid-feedback="Name is required" label="Nama Folder">
            <b-form-input id="name-input" v-model="formFolder.nama_item" required
              placeholder="Nama Folder"></b-form-input>
          </b-form-group>
          <b-form-group label="Dapat diakses">
            <b-form-select v-model="formFolder.is_public" :options="options_public"></b-form-select>
          </b-form-group>
          <b-form-group label="Posisi yang dapat melihat" v-if="formFolder.is_public == false">
            <multiselect v-model="posisiLihatArray" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>
          </b-form-group>
          <b-form-group label="Posisi yang dapat mengubah">
            <multiselect v-model="posisiEditArray" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>

          </b-form-group>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"
            @click="editFolder(formFolder.id); $bvModal.hide('modal-folder-edit')">Edit</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
        </div>
      </b-modal>

      <!-- modal create file -->
      <b-modal id="modal-file" centered title="Buat File" title-class="font-18" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label-for="name-input" invalid-feedback="Name is required" label="Nama File">
            <b-form-input id="name-input" v-model="formFile.fileName" required placeholder="Nama File"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-file v-model="formFile.file" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."></b-form-file>
          </b-form-group>
          <b-form-group label="Dapat diakses">
            <b-form-select v-model="formFile.is_public" :options="options_public"></b-form-select>
          </b-form-group>
          <b-form-group label="Posisi yang dapat melihat" v-if="formFile.is_public == false">
            <multiselect v-model="formFile.posisiLihat" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>
          </b-form-group>
          <b-form-group label="Posisi yang dapat mengubah">
            <multiselect v-model="formFile.posisiEdit" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>

          </b-form-group>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="tambahFile(); $bvModal.hide('modal-file')">Tambah
            Files</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
        </div>
      </b-modal>

      <!-- modal edit file -->
      <b-modal id="modal-file-edit" centered title="Edit File" title-class="font-18" hide-footer>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label-for="name-input" invalid-feedback="Name is required" label="Nama File">
            <b-form-input id="name-input" v-model="formFile.nama_item" required placeholder="Nama File"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-file v-model="formFile.file" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."></b-form-file>
              <a :href="formFile.filename">{{ formFile.filename }}</a>
            
          </b-form-group>
          <b-form-group label="Dapat diakses">
            <b-form-select v-model="formFile.is_public" :options="options_public"></b-form-select>
          </b-form-group>
          <b-form-group label="Posisi yang dapat melihat" v-if="formFile.is_public == false">
            <multiselect v-model="posisiLihatArray" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>
          </b-form-group>
          <b-form-group label="Posisi yang dapat mengubah">
            <multiselect v-model="posisiEditArray" tag-placeholder="Tambah posisi" placeholder="Cari posisi"
              label="nama_posisi" track-by="id" :options="listPosisi" :multiple="true"></multiselect>

          </b-form-group>
        </form>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="editFile(formFile.id); $bvModal.hide('modal-file-edit')">Tambah
            Files</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
        </div>
      </b-modal>

      <vue-simple-context-menu element-id="myUniqueId" :options="optionsMenu" ref="vueSimpleContextMenu"
        @option-clicked="optionClicked" />

    </Layout>
  </div>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import moment from "moment";

import Multiselect from 'vue-multiselect'

import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';




/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueSimpleContextMenu,
    Loading
  },
  data() {
    return {
      title: "Repository",
      items: [
        {
          text: "Project"
        },
        {
          text: "Repository",
          active: true
        }
      ],

      baseapi: localStorage.getItem("baseapi"),
      idProyek: localStorage.getItem("idProyek"),
      dataProyek: JSON.parse(localStorage.getItem('proyek')),
      user: JSON.parse(localStorage.getItem('user')),
      optionsMenu: [
        { name: "Delete", slug: "delete" },
        { name: "Edit", slug: "edit" },
      ],
      posisiEditArray: [],
      posisiLihatArray: [],
      formFolder: {
        folderName: null,
        posisiLihat: null,
        posisiEdit: null,
        is_public: true,
        posisiEditArray: [],
      },
      folderName: null,
      listData: [],
      listPosisi: [],
      isLoading: false,

      formFile: {
        fileName: null,
        is_public: true,
        file: null,
        posisiLihat: null,
        posisiEdit: null,
        
      },
      options_public: [
        { value: null, text: 'Silahkan pilih' },
        { value: true, text: 'Umum' },
        { value: false, text: 'Privasi' },
      ],
      statData: [
        {
          title: "Member",
          icon: "ri-team-fill",
          value: "10",
          subvalue: " 2.4% "
        },
        {
          title: "Task",
          icon: "ri-todo-fill",
          value: "$ 38452",
          subvalue: " 2.4% "
        },
        {
          title: "Selesai",
          icon: "ri-briefcase-4-line",
          value: "$ 15.4",
          subvalue: " 2.4% "
        }
      ]
    };
  },
  mounted() {
    this.loadDataPosisi()
    console.log(this.$route.params.id)
    if (this.$route.params.id == undefined) {
      this.loadDataRoot()
    } else {
      this.loadData()
    }

  },
  methods: {
    formattedDate(tanggal) {
      return moment(tanggal).format('DD, MMMM yyyy HH:mm:ss');
    },
    cekEkstensi(input) {
      return input.split(/[. ]+/).pop();
    },
    handleClick(event, item) {
      this.$refs.vueSimpleContextMenu.showMenu(event, item)
    },
    optionClicked(event) {
      var dataoption = event.option.slug
      var id = JSON.stringify(event.item.id)
      console.log(event)
      if (dataoption === "delete") {
        this.deleteData(id)
      } else if (dataoption === "edit") {
        this.posisiEditArray = []
        this.posisiLihatArray = []
        var id_posisiArray;
        var nama_posisiArray;
        var id_posisilihatArray;
        var nama_posisilihatArray
        
        if (event.item.tipe_item == "folder") {
          this.formFolder = event.item

          console.log(event.item.can_read_id)
          id_posisiArray = event.item.can_edit_id.split("||");
          nama_posisiArray = event.item.can_edit_position.split("||");
          id_posisilihatArray = event.item.can_read_id.split("||");
          nama_posisilihatArray = event.item.can_read_position.split("||");

          for (let index = 0; index < id_posisiArray.length; index++) {
            this.posisiEditArray.push({
              id: parseInt(id_posisiArray[index]),
              nama_posisi: nama_posisiArray[index]
            }) 
          }

          for (let index = 0; index < id_posisilihatArray.length; index++) {
            this.posisiLihatArray.push({
              id: parseInt(id_posisilihatArray[index]),
              nama_posisi: nama_posisilihatArray[index]
            }) 
          }

          this.$bvModal.show('modal-folder-edit')
        }else if(event.item.tipe_item == "file"){
          this.formFile = event.item

          console.log(event.item.can_read_id)
          id_posisiArray = event.item.can_edit_id.split("||");
          nama_posisiArray = event.item.can_edit_position.split("||");
          id_posisilihatArray = event.item.can_read_id.split("||");
          nama_posisilihatArray = event.item.can_read_position.split("||");

          for (let index = 0; index < id_posisiArray.length; index++) {
            this.posisiEditArray.push({
              id: parseInt(id_posisiArray[index]),
              nama_posisi: nama_posisiArray[index]
            }) 
          }

          for (let index = 0; index < id_posisilihatArray.length; index++) {
            this.posisiLihatArray.push({
              id: parseInt(id_posisilihatArray[index]),
              nama_posisi: nama_posisilihatArray[index]
            }) 
          }

          this.$bvModal.show('modal-file-edit')
        }

      }
    },
    loadDataRoot() {
      axios.get(this.baseapi + 'api/repository/byPosition?id_proyek=' + this.idProyek + '&id_parent=0&id_posisi='+this.dataProyek.id_posisi,
      // axios.get(this.baseapi + 'api/repository/byPosition?id_proyek=3&id_posisi=11,
        {
          headers: {
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(respon => {
          this.listData = respon.data.data
          //console.log(respon)
        })
    },
    loadData() {
      axios.get(this.baseapi + 'api/repository?filter=id_parent,=,' + this.$route.params.id,
        {
          headers: {
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(respon => {
          this.listData = respon.data.data
          //console.log(respon)
        })

      axios.get(this.baseapi + 'api/repository/' + this.$route.params.id,
        {
          headers: {
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(respon => {
          this.folderName = respon.data.data.nama_item
          console.log(respon)
        })
    },
    tambahFolder() {
      this.isLoading = true;
      var can_edit_id = ''
      var can_read_id = ''
      for (let index = 0; index < this.formFolder.posisiEdit.length; index++) {
        if (index == 0) {
          can_edit_id = this.formFolder.posisiEdit[index].id
        } else {
          can_edit_id = can_edit_id + "||" + this.formFolder.posisiEdit[index].id
        }

      }


      var bodyFormData = new FormData();

      if (this.$route.params.id == undefined) {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFolder.folderName);
        bodyFormData.append('tipe_item', 'folder');
        bodyFormData.append('is_public', this.formFolder.is_public);
        bodyFormData.append('id_parent', 0);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFolder.is_public == false) {
          for (let index = 0; index < this.formFolder.posisiLihat.length; index++) {
            if (index == 0) {
              can_read_id = this.formFolder.posisiLihat[index].id
            } else {
              can_read_id = can_read_id + "||" + this.formFolder.posisiLihat[index].id
            }

          }
          console.log(can_read_id)
          bodyFormData.append('can_read_id', can_read_id);

        }

        axios.post(this.baseapi + 'api/repository', bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadDataRoot()
          console.log(response)
          this.isLoading = false;

          this.formFolder = {}

        })
      } else {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFolder.folderName);
        bodyFormData.append('tipe_item', 'folder');
        bodyFormData.append('is_public', this.formFolder.is_public);
        bodyFormData.append('id_parent', this.$route.params.id);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFolder.is_public == false) {
          for (let index = 0; index < this.formFolder.posisiLihat.length; index++) {
            if (index == 0) {
              can_read_id = this.formFolder.posisiLihat[index].id
            } else {
              can_read_id = can_read_id + "||" + this.formFolder.posisiLihat[index].id
            }

          }
          console.log("ini log")
          console.log(can_read_id)
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository', bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadData()
          console.log(response)
          this.isLoading = false;
          this.formFolder = {}
        })
      }
    },
    editFolder(id) {
      this.isLoading = true;
      var can_edit_id = ''
      var can_read_id = ''
      for (let index = 0; index < this.posisiEditArray.length; index++) {
        if (index == 0) {
          can_edit_id = this.posisiEditArray[index].id
        } else {
          can_edit_id = can_edit_id + "||" + this.posisiEditArray[index].id
        }

      }


      var bodyFormData = new FormData();

      if (this.$route.params.id == undefined) {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFolder.nama_item);
        bodyFormData.append('tipe_item', 'folder');
        bodyFormData.append('is_public', this.formFolder.is_public);
        bodyFormData.append('id_parent', 0);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFolder.is_public == false) {
          for (let index = 0; index < this.posisiLihatArray.length; index++) {
            if (index == 0) {
              can_read_id = this.posisiLihatArray[index].id
            } else {
              can_read_id = can_read_id + "||" + this.posisiLihatArray[index].id
            }

          }
          console.log(can_read_id)
          bodyFormData.append('can_read_id', can_read_id);

        }

        axios.post(this.baseapi + 'api/repository/'+ id, bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadDataRoot()
          console.log(response)
          this.isLoading = false;

          this.formFolder = {}

        })
      } else {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFolder.nama_item);
        bodyFormData.append('tipe_item', 'folder');
        bodyFormData.append('is_public', this.formFolder.is_public);
        bodyFormData.append('id_parent', this.$route.params.id);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFolder.is_public == false) {
          for (let index = 0; index < this.posisiLihatArray.length; index++) {
            if (index == 0) {
              can_read_id = this.posisiLihatArray[index].id
            } else {
              can_read_id = can_read_id + "||" + this.posisiLihatArray[index].id
            }

          }
          console.log("ini log")
          console.log(can_read_id)
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository/'+ id, bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadData()
          console.log(response)
          this.isLoading = false;
          this.formFolder = {}
        })
      }
    },

    tambahFile() {
      this.isLoading = true;

      var can_edit_id = ''
      var can_read_id = ''
      for (let index = 0; index < this.formFile.posisiEdit.length; index++) {
        if (index == 0) {
          can_edit_id = this.formFile.posisiEdit[index].id
        } else {
          can_edit_id = can_edit_id + "||" + this.formFile.posisiEdit[index].id
        }

      }



      console.log(can_edit_id)
      var bodyFormData = new FormData();

      if (this.$route.params.id == undefined) {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFile.fileName);
        bodyFormData.append('tipe_item', 'file');
        bodyFormData.append('file', this.formFile.file);
        bodyFormData.append('is_public', this.formFile.is_public);
        bodyFormData.append('id_parent', 0);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFile.is_public == false) {
          for (let index = 0; index < this.formFile.posisiLihat.length; index++) {
            if (index == 0) {
              can_read_id = this.formFile.posisiLihat[index].id
            } else {
              can_read_id = can_read_id + "||" + this.formFile.posisiLihat[index].id
            }

          }
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository', bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadDataRoot()
          console.log(response)
          this.isLoading = false;
          this.formFile = {}
        })
      } else {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFile.fileName);
        bodyFormData.append('tipe_item', 'file');
        bodyFormData.append('file', this.formFile.file);
        bodyFormData.append('is_public', this.formFile.is_public);
        bodyFormData.append('id_parent', this.$route.params.id);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFile.is_public == false) {
          for (let index = 0; index < this.formFile.posisiLihat.length; index++) {
            if (index == 0) {
              can_read_id = this.formFile.posisiLihat[index].id
            } else {
              can_read_id = can_read_id + "||" + this.formFile.posisiLihat[index].id
            }

          }
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository', bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadData()
          console.log(response)
          this.isLoading = false;
          this.formFile = {}
        })
      }
    },

    editFile(id) {
      this.isLoading = true;

      var can_edit_id = ''
      var can_read_id = ''
      for (let index = 0; index < this.posisiEditArray.length; index++) {
        if (index == 0) {
          can_edit_id = this.posisiEditArray[index].id
        } else {
          can_edit_id = can_edit_id + "||" + this.posisiEditArray[index].id
        }

      }



      console.log(can_edit_id)
      var bodyFormData = new FormData();

      if (this.$route.params.id == undefined) {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFile.nama_item);
        bodyFormData.append('tipe_item', 'file');
        bodyFormData.append('file', this.formFile.file);
        bodyFormData.append('is_public', this.formFile.is_public);
        bodyFormData.append('id_parent', 0);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFile.is_public == false) {
          for (let index = 0; index < this.posisiLihatArray.length; index++) {
            if (index == 0) {
              can_read_id = this.posisiLihatArray[index].id
            } else {
              can_read_id = can_read_id + "||" + this.posisiLihatArray[index].id
            }

          }
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository/'+id, bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadDataRoot()
          console.log(response)
          this.isLoading = false;
          this.formFile = {}
        })
      } else {
        bodyFormData.append('id_user', this.user.id);
        bodyFormData.append('id_proyek', this.idProyek);
        bodyFormData.append('nama_item', this.formFile.nama_item);
        bodyFormData.append('tipe_item', 'file');
        bodyFormData.append('file', this.formFile.file);
        bodyFormData.append('is_public', this.formFile.is_public);
        bodyFormData.append('id_parent', this.$route.params.id);
        bodyFormData.append('can_edit_id', can_edit_id);

        if (this.formFile.is_public == false) {
          for (let index = 0; index < this.posisiLihatArray.length; index++) {
            if (index == 0) {
              can_read_id = this.posisiLihatArray[index].id
            } else {
              can_read_id = can_read_id + "||" + this.posisiLihatArray[index].id
            }

          }
          bodyFormData.append('can_read_id', can_read_id);
        }

        axios.post(this.baseapi + 'api/repository/'+id, bodyFormData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(response => {
          this.loadData()
          console.log(response)
          this.isLoading = false;
          this.formFile = {}
        })
      }
    },

    loadDataPosisi() {
      axios.get(this.baseapi + 'api/posisi?filter=id_proyek,=,' + this.idProyek,
        {
          headers: {
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(respon => {
          this.listPosisi = respon.data.data
        });
    },

    deleteData(id) {
      axios.delete(this.baseapi + 'api/repository/' + id,
        {
          headers: {
            Authorization: `Bearer ` + this.user.accessToken
          }
        }).then(respon => {
          if (this.$route.params.id == undefined) {
            this.loadDataRoot()
          } else {
            this.loadData()
          }
          console.log(respon)
        });
    }
  }
};
</script>
